export default [
   {
      id: 0,
      title: "Blockchain Developer",
      location: "Mumbai, India",
      images: "/static/images/chainsense/blockchain-developer.gif",
      skills: ["Solidity","Web3"],
      duration: "01 Nov 2021",
      description: "We are looking for a highly capable blockchain developer to design, implement, and distribute a secure blockchain-based network. You will be analysing our blockchain needs, designing customized blockchain technologies, and launching and maintaining our blockchain network. To ensure success as a blockchain developer, you should possess extensive knowledge of programming languages used for blockchain development and experience in cryptography. An outstanding blockchain developer will be someone whose expertise translates into secure, fast, and efficient digital transactions."

    },
    {
      id: 1,
      title: "UI Developer",
      location: "Mumbai, India",
      images: "/static/images/chainsense/uideveloper.gif",
      skills: ["Angular","React","HTML5","CSS3","JavaScript","JQuery","Git"],
      duration: "01 Nov 2021",
      description: "We are currently hiring a passionate, user centered UI/UX Designer to join a collaborative and innovative team to create visually delightful and easy-to-use digital products in a fast-paced environment."
    },
    {
      id: 2,
      title: "NodeJs Developer",
      location: "Mumbai, India",
      images: "/static/images/chainsense/programer.gif",
      skills: ["Node.js", "HTML5","CSS3","JavaScript","web stacks","Git"],
      duration: "01 Nov 2021",
      description: "We are currently hiring a passionate, user centered Node Js Developer to join a collaborative and innovative team to create visually delightful and easy-to-use digital products in a fast-paced environment."
    },
]