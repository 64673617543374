<template>
  <div class="emb-aboutus-wrap">
    <emb-page-title
      heading="Careers"
      subHeading="Creative team and wide goals."
    >
    </emb-page-title>
    <div class="emb-about-content">
      <div class="about-page section-gap">
        <v-container grid-list-xl>
          <v-layout row wrap align-center>
            <v-flex xs12 xl pr-5>
              <h3 class="font-weight-bold font-italic mb-6">
                We delivering a business oriented niche solution with an
                integrated custom consensus algorithm within the blockchain
                architecture for a comprehensive solution.
              </h3>
              <p class="mb-6">
                Blockchain is evolving with time and the crypto market is the
                biggest proof of that. There are networks that can process
                thousands of transactions which was not possible when the
                blockchain had made a debut. It still has a long to go before it
                becomes a norm in the blockchain industry. The ripple effect has
                already begun. There are regions and hospitals that are
                experimenting with the technology for daily implications,
                pharmaceutical firms utilizing blockchain for SCM (Supply Chain
                Management), and researchers using DLTs to access databases for
                clinical trial and drugs records. So it’s fairly safe to say
                Blockchain is Inevitable, it is going to transform the
                healthcare sector in coming time.
              </p>
            </v-flex>
          </v-layout>
        </v-container>
      </div>
      <div class="about-info section-gap bg-grey">
        <v-container grid-list-xl py-0>
          <v-layout row wrap align-start mb-md-0 mb-6>
            <v-flex
              xl4
              text-md-left
              text-center
              v-for="(item, index) in openings"
              :key="index"
            >
              <img
                :src="item.images ? item.images : '/static/images/our-mission.jpg'"
                :alt="item.title"
                class="mb-3"
                style="filter:blur(1px)"
              />
              <h3 class="font-weight-bold">
                {{ item.title }}
              </h3>
              <p>{{ item.location }}</p>
              <div style="min-height: 90px">
                <v-chip
                  class="ma-1"
                  v-for="(skill, index) in item.skills"
                  :key="index"
                  >{{ skill }}</v-chip
                >
              </div>
              <div class="text-justify" v-html="item.description"></div>

              <v-dialog transition="dialog-bottom-transition" max-width="600">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn block color="primary" v-bind="attrs" v-on="on"
                    >Apply Now</v-btn
                  >
                </template>
                <template v-slot:default="dialog">
                  <v-card>
                    <v-card-text>
                      <div class="text-h4 pa-12">
                        Apply for {{ item.title }}
                      </div>
                      <v-form ref="form" v-model="valid" lazy-validation>
                        <v-text-field
                          outlined
                          v-model="name"
                          :rules="nameRules"
                          label="Name"
                          required
                          prepend-icon="mdi-account-box"
                        ></v-text-field>

                        <v-text-field
                          outlined
                          v-model="email"
                          :rules="emailRules"
                          label="E-mail"
                          required
                          prepend-icon="mdi-mail"
                        ></v-text-field>

                        <v-text-field
                          outlined
                          v-model="phoneNumber"
                          :rules="basicRules"
                          label="Contact Number"
                          required
                          prepend-icon="mdi-phone"
                        ></v-text-field>
                        
                        <v-file-input
                          outlined
                          label="Resume"
                          truncate-length="15"
                          
                        ></v-file-input>

                        <v-btn color="accent" sm block @click="Submit">
                          Submit
                        </v-btn>
                      </v-form>
                    </v-card-text>
                  </v-card>
                </template>
              </v-dialog>
            </v-flex>
          </v-layout>
        </v-container>
      </div>
    </div>
  </div>
</template>

<script>
import openings from "../assets/data/openings";
export default {
  components: {},
  data() {
    return {
      openings,
      dialog:false,
      slickOptions: {
        autoplay: false,
        slidesToShow: 1,
        infinite: false,
        arrows: false,
        dots: false,
        slidesToScroll: 1,
        swipe: true,
        seed: 100,
        rtl: this.rtlLayout,
        responsive: [
          {
            breakpoint: 390,
          },
        ],
      },
      valid: true,
      basicRules: [(v) => !!v || "This is required"],
      name: "",
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
      ],      
      phoneNumber: "",
      phoneRules: [
        (v) => !!v || "Name is required",
        (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
      ],
      email: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      /**
       *** Data
       **/
      reviews: [
        {
          img: "/static/images/user-1.jpg",
          name: "Albert Gomez",
          position: "Head, ABS Infos",
          desc: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Autexpedita quasi voluptatibus tenetur nisi ad earum atque laboriosam accusamus. Doloremque sapiente aliquid quae. Animi ratione dolorum repellat amet nihil? Magnam?",
        },
        {
          img: "/static/images/user-2.jpg",
          name: "Tristan Cyrus",
          position: "Head, ABS Infos",
          desc: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Autexpedita quasi voluptatibus tenetur nisi ad earum atque laboriosam accusamus. Doloremque sapiente aliquid quae. Animi ratione dolorum repellat amet nihil? Magnam?",
        },
        {
          img: "/static/images/user-3.jpg",
          name: "Kendrick Younes",
          position: "Head, ABS Infos",
          desc: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Autexpedita quasi voluptatibus tenetur nisi ad earum atque laboriosam accusamus. Doloremque sapiente aliquid quae. Animi ratione dolorum repellat amet nihil? Magnam?",
        },
        {
          img: "/static/images/user-4.jpg",
          name: "Beljium Hugh",
          position: "Head, ABS Infos",
          desc: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Autexpedita quasi voluptatibus tenetur nisi ad earum atque laboriosam accusamus. Doloremque sapiente aliquid quae. Animi ratione dolorum repellat amet nihil? Magnam?",
        },
      ],
      teamListing: null,
    };
  },
  mounted() {
    this.getTeam();
  },
  methods: {
    
    Submit() {
       this.$refs.form.validate();
    },
  },
};
</script>

<style scoped>
img[alt="mission"] {
  border-radius: 65px;
  filter: grayscale(0.7);
}
</style>
